// VITE deals with this
import '../scss/app.scss';

// Set some helpful variables
import {setCSSVars} from './utils';

import AOS from 'aos';
import Glide from '@glidejs/glide/dist/glide.min';
import 'aos/dist/aos.css';
import "@glidejs/glide/dist/css/glide.core.min.css";
import "@glidejs/glide/dist/css/glide.theme.css";
import axios from 'axios';
import Accordion from 'accordion-js';
import 'accordion-js/dist/accordion.min.css';

setCSSVars();

// RESIZE HANDLER
window.addEventListener('resize', () => {
    setCSSVars();
});

AOS.init({
    startEvent: 'load',
    once: true,
    disable: 'mobile',
    disableMutationObserver: false,
});
AOS.refresh(true);

var prevScrollpos = window.scrollY;

window.onscroll = function () {
    var currentScrollPos = (1 / (window.scrollY * 0) === 1 / 0) ? window.scrollY : 0;

    if (prevScrollpos >= currentScrollPos) {
        document.getElementById("header").style.top = "0";
    } else {
        document.getElementById("header").style.top = "-100px";
    }

    prevScrollpos = currentScrollPos;
}

// INITIALIZE ACCORDIONS
const accordions = Array.from(document.querySelectorAll('.accordion-container'));

if (accordions) {
    let id = null;

    accordions.forEach(item => {

        new Accordion('.acc-container--' + item.getAttribute('data-accid'));
    });
}

// HAMBURGER MENU
const hamburger = document.querySelector('.mobile-nav-trigger');
const mainNavigation = document.querySelector('.main-navigation');

hamburger.addEventListener('click', () => {
    hamburger.classList.toggle('active');
    mainNavigation.classList.toggle('active');
    document.body.classList.toggle('no-scroll');
});

/*
if (document.querySelector('.c-blockForm')) {
    console.log('here');
    const contactForm = document.querySelector('form');

    if (contactForm) {
        console.log('there');
        contactForm.addEventListener('beforeValidate', () => {
            console.log('beforeValidate')
        });
    }
}

if (document.querySelector('.contact-float')) {
    document.querySelectorAll('.contact-float .icon, .contact-float .label').forEach(item => {
        item.addEventListener('click', function (event) {
            console.log('add');
            document.body.classList.add('no-scroll');
            document.querySelector('.contact-float').classList.add('active');
        });
    });
}

if (document.querySelector('.close-btn')) {
    document.querySelector('.close-btn').addEventListener('click', function (event) {
        console.log('close');
        document.body.classList.remove('no-scroll');
        document.querySelector('.contact-float').classList.remove('active');
    });
}
*/

if (document.querySelector('.glide')) {
    new Glide('.glide', {
        type: 'slider',
        rewind: false,
        startAt: 0,
        perView: 2,
        focusAt: 'center',
    }).mount()
}

document.addEventListener("DOMContentLoaded", function (event) {

});
